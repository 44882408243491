/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */
.asssssssssssss {
  /* background-image: linear-gradient(
      to right bottom,
      rgba(7, 6, 6, 0.466),
      rgba(63, 60, 58, 0.39)
    ),
    url(img/3114610.jpg); */
  background-size: cover; /* Cover the entire page */
  background-repeat: no-repeat; /*Do not repeat the image */
  background-position: center center; /* Center the image */
  background-attachment: fixed;
  height: 100vh;overflow-y: scroll;
}
.main-nav {
  background-color: rgb(8, 61, 19);
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.main-nav-list {
  list-style: none;
  font-size: 20px;
  color: white;
}
.weewewew {
  color: white;
  font-size: 50px;
  margin-left: 580px;
  margin-bottom: 20px;
}
.container {
  max-width: 120rem;
  padding: 0 3.2rem;
  margin: 0 auto;
}

.grid {
  display: grid;
  row-gap: 9.6rem;
  column-gap: 6.4rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin-bottom: 4.8rem !important;
}

.plantss {
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.08);
  border-radius: 11px;
  overflow: hidden;
  transition: all 0.4s;
  border: 1.7px solid #0b200a;
}

.plantss:hover {
  transform: translateY(-1.2rem);
  box-shadow: 0 3.2rem 6.4rem rgba(0, 0, 0, 0.06);
}

.plant-content {
  padding: 1rem 2.4rem 1.8rem 2.4rem;
  /* background-color: white; */
  backdrop-filter: blur(30px);
}
.title {
  font-size: 1.8rem;
  color: #fff;
  font-weight: 600;
  margin-bottom: 1.2rem;
}

.text {
  font-size: 1.2rem;
  gap: 0.5rem;
  color: #fff;
}

.plant-img {
  width: 100%;
  aspect-ratio: 1.5;
}
