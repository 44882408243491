.fullGarden {
    /* background-color: rgba(0, 0, 0, 0.507); */
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
  .nav {
    /* height: 10%;width: 100%; */
    /* background-color: black; */
    padding: 10px;
    color: white;
    font-weight: bold;
    font-size: 50px;
    /* margin: 0 0 20px 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* backdrop-filter: blur(10px); */
  }
  .body {
    /* background-color: red; */
    overflow-y: scroll;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
  .eachItem {
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .search_contain {
    /* height: 10%; */
    width: 20%;
    /* background-color: blue; */
    background: transparent;
    border-radius: 40px;
    border: 2px solid #fff;
    font-size: 20px;
    font-weight: normal;
    padding: 7px;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .iconsss {
    /* height: 30%; */
    color: #fff;
    font-size: 20px;
    /* background-color: red; */
    /* width: 30%; */
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
  }
  