.data-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  border-radius: 15px;
  padding: 20px;
  border: 1px solid white;
  color: white;
  width: 200px;
}

.model-container {
  z-index: 2;
  position: absolute;
  top: 0;
  height: 200px;
  /* background: red; */
  width: 100%;
}

.plant-name {
  font-size: 1.5em;
  cursor: pointer;
  margin: 180px 0 0 0;
  /* margin-top: 180px; */
  z-index: 2; /* Ensure it's above the pseudo-element */
}
