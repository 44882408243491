.authHead{
    height:100vh; width:100%; overflow: hidden;
    display: flex;justify-content: center;align-items: center;
    /* background-color: red; */
}.containAuth{
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    background-color: rgb(7, 73, 7);
}.authcenter{
    display: flex;justify-content: center;align-items: center;flex-direction: column;
    height: 70%;width: 40%;
    border: 3px solid rgb(0, 0, 0);
    border-radius: 80px;
    filter: drop-shadow(0 0 100px rgb(0, 0, 0));
    overflow: hidden;
    /* background-color: rgb(91, 230, 73); */
}.welcum{
    color: white;
    font-family: 'Font_with_Serifs';
    font-weight: bold;
    font-size: 130px;
    margin-top: 0;
    filter: drop-shadow(10px 15px 10px rgb(68, 6, 6));
    margin-bottom: 2%;
}.mails{
    color: rgb(255, 255, 255);
    resize:none;
    border: none;
    outline: none;
    background: none;border-radius: 0;
    width: 50%;
    font-size: 20px;
    height: 5%;
    margin-top: 3%;
    margin-bottom: 2%;
    border-bottom:4px solid black;
    font-family: monospace;font-weight: bold;
    filter: drop-shadow(0 10px 10px rgb(20, 90, 2));
    overflow: hidden;
}.submitAuth{
    display: flex;justify-content: center;align-items: center;
    height:9%;width: 23%;
    border-radius: 30px;
    font-family: monospace;
    margin-top: 3%;
    font-size: 29px;
    margin-bottom: 2.5%;
    color: white;
    background-color: darkgreen;
    filter: drop-shadow(0 0 10px black);
    transition: transform 1s ease;
    cursor:pointer;
}.submitAuth:hover{
    transform: scale(1.2);
    transition: transform 1s ease;
}.show img{
    right: 25%;
    position: absolute;
    cursor: pointer;
    margin-top: -8%;
    height: 30px;
    /* background-color: blue; */
}.error{
    margin-bottom: -0.7%;font-size: 15px;font-weight: bold;
    margin-top:-2%;margin-left: -0%;font-family: monospace;color: red;
    /* background-color: blue; */
}.signup{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    /* background-color: blue; */
}.mails::placeholder {
    color: #000000;
    font-family:cursive;
    font-weight: 100;
}
@media (max-width: 1100px) {
.welcum{font-size: 100px;}
.show img{margin-top: -10%;height: 25px;}
.error{font-size: 10px;font-weight: bold;}
.submitAuth{font-size: 22px;}
}@media (max-width: 670px) {
    .welcum{font-size: 60px;}
    .show img{margin-top: -15%;height: 25px;}
    .signup{font-size: 60%;}
    .submitAuth{font-size: 12px;}
}